<template>
  <div class="home home_image_comin">
      <div>
        <div class="home_title home_title_in">{{ home_title }}</div>
        <div class="home_button home_button_in">
          <el-button class="button_style" type="text" @click="go_login()">{{ button_text }}</el-button>
          <el-button class="button_tourist" type="text" @click="go_course()">{{ tourist }}</el-button>
        </div>
      </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
export default {
  name: 'Home',
  setup() {
    const router = useRouter()

    const background_image = ref('/static/background.jpg')
    const home_title = ref('新 文 科 Python 程 序 设 计')
    const button_text = ref('登 录  Login')
    const tourist = ref('课程主页')

    const go_login = () => {
      router.push('/login')
    }
    const go_course = () => {
      router.push('/course')
    }
    return {
      home_title,
      background_image,
      button_text,
      tourist,
      go_login,
      go_course
    }
  },
}
</script>
<style scoped>
.home {
  height: 92vh;
  width: 100vw;
  background-image: url("/static/background.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.home_title {
  color: rgb(255, 255, 255);
  font-size: 3.5vw;
  font-family: STSong;
  text-align: center;
  padding-top: 30vh;
}
.home_button {
  text-align: center;
  margin-top: 4vh;
}
.button_style {
  color: aliceblue;
  background-color: rgba(0, 0, 0, 0.3);
  border: 1.8px solid aliceblue;
  border-radius: 35px;
  font-size: 1.8vw;
  padding: 0.9vw 1.8vw;
}
.button_style:hover {
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 2px 2px 10px #aaaaaa;
}
.button_tourist {
  color: rgb(245, 245, 245);
  font-size: 1.5vw;
  letter-spacing: 2px;
  border-radius: 35px;
  padding: 1.1vw 1vw;
  margin-left: 1vw;
}
.button_tourist:hover {
  background-color: #aaaaaa55;
  box-shadow: 2px 2px 10px #aaaaaa;
  border: 1px solid aliceblue;
}
@keyframes title_comin {
  0% {
    opacity: 0;
    transform: translateX(-150px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes image_comin {
  0% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}
@keyframes button_comin {
  0% {
    opacity: 0;
    transform: translateX(-250px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
.home_title_in {
  animation: title_comin 0.8s;
}
.home_image_comin {
  animation: image_comin 0.8s;
}
.home_button_in {
  animation: button_comin 1.2s;
}
</style>