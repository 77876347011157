<template>
  <div class="login">
    <div style="padding:10px 10px">
      <el-button :icon="ArrowLeftBold" circle class="icon_button" @click="go_back"></el-button>
    </div>
    <div align="center">
      <el-form class="form_class">
        <el-form-item class="item_class">
          <el-input placeholder="请输入用户名：" v-model="username"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input placeholder="请输入密码：" v-model="password" show-password></el-input>
        </el-form-item>
        <el-form-item align="center">
          <div>
            <el-button class="login_button" @click="onsubmit">登录 Login</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { ArrowLeftBold } from '@element-plus/icons'
export default {
  data() {
    return {
      username: "",
      password: "",
      ArrowLeftBold,
    }
  },
  methods: {
    onsubmit() {
      if (this.username != "" && this.password != "") {
        this.axios.post("https://api.class.yingshinet.com/login",
          {
            student_id: this.username,
            password: this.password,
          })
          .then(response => {
            if (response.data.msg == "Success") {
              this.$message({
                message: "登陆成功！QAQ",
                type: "success"
              })
              this.$store.commit("change_login_state", "1")
              this.$store.commit("change_relate_course", response.data.related_classes)
              this.$store.commit("change_username", response.data.userName)
              this.$router.push("/course")
            }
          }).catch((error) => {
            if (error.response.data.msg == "Invalid username or password.") {
              this.$message({
                message: "账号或密码有误！",
                type: "error"
              })
            }
          })
      } else {
        this.$message({
          message: "用户名与密码不可或缺！Please",
          type: "error"
        })
      }
    },
    go_back() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.login {
  width: 100vw;
  height: 100vh;
  background-image: url("/static/background.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.form_class {
  width: 30vw;
  padding-top: 25vh;
}
.item_class {
  margin-bottom: 30px;
}
.icon_button {
  border: rgba(255, 255, 255, 0);
  background: rgba(255, 255, 255, 0);
}
:deep(.el-input__inner) {
  color: rgb(255, 255, 255);
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 22.5px;
  border: rgba(255, 255, 255, 0);
  height: 45px;
  line-height: 45px;
  font-size: 15px;
}
.login_button {
  float: left;
  width: 99%;
  margin: 5px 1% 0 1%;
}
:deep(.el-button) {
  height: 45px;
  font-size: 15px;
  color: rgb(255, 255, 255);
  background: rgba(0, 0, 0, 0.5);
  border-radius: 22.5px;
  border-color: rgb(255, 255, 255);
}
:deep(.el-button:hover) {
  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
}
:deep(.el-button:focus) {
  background: rgba(255, 255, 255, 0.8);
  color: rgb(70, 70, 70);
}
/* 谷歌 */
:deep(.el-input__inner::-webkit-input-placeholder) {
  color: aliceblue;
}
/* 火狐 */
:deep(.el-input__inner:-moz-placeholder) {
  color: #fff;
}
/*ie*/
:deep(.el-input__inner:-ms-input-placeholder) {
  color: #fff;
}
</style>