<template>
    <div class="section_style">
        <div>
            <el-button :icon="ArrowLeftBold" circle @click="go_back()"></el-button>
        </div>
        <div>
            <div align="center">
                <h2 style="letter-spacing:5px">{{ paragraph.paragraph_name }}</h2>
            </div>
            <div
                align="center"
                v-show="paragraph.short_video != null && paragraph.short_video != ''"
            >
                <video
                    class="video_style"
                    controls="controls"
                    :src="paragraph.short_video"
                    type="video/mp4"
                ></video>
            </div>
            <div style="margin:10px 0">
                <div class="text_style" :style="this.text_style">{{ paragraph.text }}</div>
                <el-image
                    v-show="paragraph.pic != null && paragraph.pic != ''"
                    class="image_style"
                    :src="paragraph.pic"
                    :preview-src-list="new Array(paragraph.pic)"
                ></el-image>
            </div>
            <div style="height: 10vh"></div>
        </div>
    </div>
</template>

<script>
import { ArrowLeftBold } from '@element-plus/icons'
export default {
    props: {
        paragraph: Object,
    },
    data() {
        return {
            ArrowLeftBold,
            text_style: {
                width: this.paragraph.pic != null && this.paragraph.pic != '' ? '64%' : '96%'
            }
        }
    },
    methods: {
        go_back() {
            this.$emit('change_paragraph', "back")
        },
    },
}
</script>

<style scoped>
.section_style {
    height: 97vh;
}
.video_style {
    width: 96%;
    border-radius: 10px;
    margin-top: 5px;
}
.text_style {
    font-size: 2vh;
    margin: 0 0 0 2.5%;
    padding-right: 2%;
    line-height: 2em;
    display: inline-block;
    vertical-align: middle;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-all;
}
.image_style {
    width: 29.5%;
    display: inline-block;
    vertical-align: middle;
    border-radius: 10px;
}
</style>