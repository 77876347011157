<template>
    <div style="width:60vw; margin:30px 0 0 20vw">
        <h3 align="center" style="color:#2E59A7">{{ title }}</h3>
        <el-form ref="form" label-width="90px">
            <el-form-item label="段落名称" required>
                <el-input v-model="paragraph_title"></el-input>
            </el-form-item>
            <el-form-item label="段落内容" required>
                <el-input
                    type="textarea"
                    class="content_input"
                    :autosize="{ minRows: 6 }"
                    v-model="paragraph_content"
                ></el-input>
            </el-form-item>
            <el-form-item label="段落配图">
                <el-upload
                    action="#"
                    accept="image/*"
                    list-type="picture-card"
                    :auto-upload="false"
                    :limit="1"
                    :on-preview="handlePreview"
                    :on-remove="handleRemove"
                    :on-change="handleChange"
                >
                    <el-icon>
                        <plus />
                    </el-icon>
                </el-upload>
                <el-dialog :visible="dialogVisible">
                    <img width="100%" :src="dialogImageUrl" alt />
                </el-dialog>
            </el-form-item>
            <el-form-item label="段落视频">
                <el-upload
                    action="#"
                    accept="video/mp4"
                    list-type="text"
                    :auto-upload="false"
                    :limit="1"
                    :on-remove="videoRemove"
                    :on-change="videoChange"
                    class="upload-demo"
                    drag
                    multiple
                >
                    <el-icon class="el-icon--upload">
                        <upload-filled />
                    </el-icon>
                    <div class="el-upload__text">
                        只允许.mp4文件
                        <em>点击上传</em>
                    </div>
                </el-upload>
                <el-dialog v-model="dialogVisible">
                    <img width="100%" :src="dialogImageUrl" alt />
                </el-dialog>
            </el-form-item>
            <el-form-item style="font-size:1vw; margin-bottom:30px">
                <el-button :loading="loading" @click="onSubmit()">{{ submit }}</el-button>
                <el-button @click="go_back()" style="margin-left:30px">返回</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { Plus, UploadFilled } from '@element-plus/icons'
export default {
    components: {
        Plus,
        UploadFilled,
    },
    data() {
        return {
            title: '修改段落',
            submit: '修改',
            loading: false,

            paragraph_title: '',
            paragraph_content: '',

            paragraph_image: null,
            paragraph_video: null,
            dialogImageUrl: '',
            dialogVisible: false,

            class_id: '',
            chapter_id: '',
            section_id: '',
            paragraph_id: '',

            page_purpose: 1,
        }
    },
    methods: {
        go_back() {
            this.$router.push({
                path: "/course",
                query: {
                    class_id: this.course_id,
                    chapter_id: this.chapter_id,
                    section_id: this.section_id,
                }
            });
        },
        onSubmit() {
            this.loading = true
            console.log(this.paragraph_image)
            console.log(this.paragraph_video)
            if (this.paragraph_title != '' && this.paragraph_content != '') {
                if (this.page_purpose == 0) {
                    this.axios.post("https://api.class.yingshinet.com/outline/update", {
                        class_id: this.course_id,
                        chapter_id: this.chapter_id,
                        section_id: this.section_id,
                        paragraph_id: this.paragraph_id,
                        paragraph_name: this.paragraph_title,
                        text: this.paragraph_content,
                        long_video: this.$store.state.username,
                    }).then(response => {
                        if (response.data.msg == "Success") {
                            this.$message({
                                message: "内容修改成功！",
                                type: "success"
                            })
                            if (this.paragraph_image == null && this.paragraph_video == null) {
                                this.loading = false
                                this.$router.push({
                                    path: "/course",
                                    query: {
                                        class_id: this.course_id,
                                        chapter_id: this.chapter_id,
                                        section_id: this.section_id,
                                    }
                                });
                            } else {
                                this.upload_image_video()
                            }
                        } else {
                            this.$message({
                                message: "修改失败！",
                                type: "error,"
                            })
                        }
                    }).catch((error) => {
                        console.log(error.response)
                        this.$message({
                            message: "修改失败！",
                            type: "error"
                        })
                    })
                } else {
                    this.axios.post("https://api.class.yingshinet.com/outline/insert", {
                        class_id: this.course_id,
                        chapter_id: this.chapter_id,
                        section_id: this.section_id,
                        paragraph_id: this.paragraph_id,
                        paragraph_name: this.paragraph_title,
                        text: this.paragraph_content,
                        long_video: this.$store.state.username,
                    }).then(response => {
                        if (response.data.msg == "Success") {
                            this.$message({
                                message: "内容添加成功！",
                                type: "success"
                            })
                            if (this.paragraph_image == null && this.paragraph_video == null) {
                                this.loading = false
                                this.$router.push({
                                    path: "/course",
                                    query: {
                                        class_id: this.course_id,
                                        chapter_id: this.chapter_id,
                                        section_id: this.section_id,
                                    }
                                });
                            } else {
                                this.upload_image_video()
                            }
                        } else {
                            this.$message({
                                message: "添加失败！",
                                type: "error,"
                            })
                        }
                    }).catch((error) => {
                        console.log(error.response)
                        this.$message({
                            message: "添加失败！",
                            type: "error"
                        })
                    })
                }
            } else {
                this.loading = false
            }
        },
        upload_image_video() {
            var path = '/' + this.course_id + '/' + this.chapter_id + '/' + this.section_id + '/' + this.paragraph_id
            if (this.paragraph_image != null) {
                let file = this.paragraph_image
                let poster_param = new FormData()
                let config = { headers: { 'Content-Type': 'multipart/form-data' } }
                poster_param.append('file', file)
                this.axios.post("https://api.class.yingshinet.com/outline/savepic" + path, poster_param, config)
                    .then(image_response => {
                        if (image_response.data.msg == "Success") {
                            this.$message({
                                message: "图片上传成功！",
                                type: "success"
                            })
                            if (this.paragraph_video == null) {
                                this.loading = false
                                this.$router.push({
                                    path: "/course",
                                    query: {
                                        class_id: this.course_id,
                                        chapter_id: this.chapter_id,
                                        section_id: this.section_id,
                                    }
                                });
                            }
                        }
                    })
            }
            if (this.paragraph_video != null) {
                let file = this.paragraph_video
                let poster_param = new FormData()
                let config = { headers: { 'Content-Type': 'multipart/form-data' } }
                poster_param.append('file', file)
                this.axios.post("https://api.class.yingshinet.com/outline/savevideo" + path, poster_param, config)
                    .then(video_response => {
                        if (video_response.data.msg == "Success") {
                            this.$message({
                                message: "视频上传成功！",
                                type: "success"
                            })
                            this.loading = false
                            this.$router.push({
                                path: "/course",
                                query: {
                                    class_id: this.course_id,
                                    chapter_id: this.chapter_id,
                                    section_id: this.section_id,
                                }
                            });
                        }
                    })
            }
        },
        handleChange(file, fileList) {
            this.paragraph_image = file.raw
            console.log(file, fileList)
        },
        handleRemove(file, fileList) {
            this.paragraph_image = null
            console.log(file, fileList)
        },
        handlePreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
            console.log(file)
        },
        videoChange(file, fileList) {
            this.paragraph_video = file.raw
            console.log(file, fileList)
        },
        videoRemove(file, fileList) {
            this.paragraph_video = null
            console.log(file, fileList)
        },
    },
    created() {
        if (this.$route.query.paragraph_id != undefined) {
            this.course_id = this.$route.query.class_id
            this.chapter_id = this.$route.query.chapter_id
            this.section_id = this.$route.query.section_id
            this.paragraph_id = this.$route.query.paragraph_id
            this.page_purpose = 0
            this.axios.post("https://api.class.yingshinet.com/outline/select",
                {
                    class_id: this.course_id,
                    chapter_id: this.chapter_id,
                    section_id: this.section_id,
                    paragraph_id: this.paragraph_id
                }).then(response => {
                    if (response.data.msg == "success") {
                        this.paragraph_title = response.data.data[0].paragraph_name
                        this.paragraph_content = response.data.data[0].text
                    }
                })
        } else {
            this.course_id = this.$route.query.class_id
            this.chapter_id = this.$route.query.chapter_id
            this.section_id = this.$route.query.section_id
            this.axios.post("https://api.class.yingshinet.com/outline/select", {
                class_id: this.course_id,
                chapter_id: this.chapter_id,
                section_id: this.section_id,
            }).then(response => {
                this.paragraph_id = response.data.data.length + 1
            })
            this.page_purpose = 1
            this.title = '添加段落'
            this.submit = '添加'
        }
    }
}
</script>

<style scoped>
:deep(.el-button) {
    background: #2e59a7;
    border-color: #2e59a7;
    color: aliceblue;
}
.content_input {
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-all;
}
</style>