import { createStore } from 'vuex'

export default createStore({
  state: {
    login_state: window.sessionStorage.getItem("login_state") == "1"?"1":"0",
    relate_course: window.sessionStorage.getItem("relate_course"),
    username: window.sessionStorage.getItem("username"),
  },
  mutations: {
    change_login_state(state, user_state){
      window.sessionStorage.setItem("login_state", user_state)
      state.login_state = user_state
    },
    change_relate_course(state, course){
      window.sessionStorage.setItem("relate_course", course)
      state.relate_course = course
    },
    change_username(state, user){
      window.sessionStorage.setItem("username", user)
      state.username = user
    }
  },
  actions: {
  },
  modules: {
  }
})
