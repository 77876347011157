<template>
  <div class="header" v-if="!$route.meta.hide">
    <el-image class="header_image" :src="cuc_logo" :fit="contain" @click="go_home()"></el-image>
    <el-button class="header_button" type="text" :icon="Avatar" @click="go_login()" v-show="this.$store.state.login_state == '0'">{{ login }}</el-button>
  </div>
  <router-view />
</template>
<script>
import { ref } from 'vue'
import { Avatar } from '@element-plus/icons'
import { useRouter } from 'vue-router'
export default {
  setup() {
    const router = useRouter()

    const cuc_logo = ref('/static/cuclogo.png')
    const login = ref(' 登 录 ')
    const go_home = () => {
      router.push('/')
    }
    const go_login = () => {
      router.push('/login')
    }
    return {
      cuc_logo,
      login,
      Avatar,
      go_home,
      go_login,
    }
  }
}
</script>
<style>
::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}
</style>
<style lang="scss">
body {
  display: block;
  margin: 0px;
  padding: 0px;
}
.el-icon {
  --font-size: 16px !important;
}
.header {
  z-index: 1;
  height: 8vh;
  min-height: 50px;
  background-color: rgb(46, 89, 167);
}
.header_image {
  z-index: 10;
  height: 80%;
  margin-left: 20px;
  padding-top: 0.8vh;
}
.header_image:hover {
  cursor: pointer;
}
.header_button {
  height: 6vh;
  font-size: 16px;
  color: aliceblue;
  float: right;
  margin: 1vh 20px 0 0;
  padding: 0 8px;
}
.header_button:hover {
  color: aliceblue;
  border: 1px solid aliceblue;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 30px;
}
</style>