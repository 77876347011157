<template>
  <el-container>
    <!-- 侧边栏 -->
    <el-aside class="aside">
      <div class="outline_title" align="center">
        <span>课程列表</span>
        <el-tooltip
          class="item"
          effect="dark"
          content="添加课程"
          placement="right"
        >
          <el-icon
            class="add_style"
            @click="add_course"
            v-show="
              this.$store.state.login_state == '1' &&
              this.$store.state.username == 'admin'
            "
          >
            <Plus />
          </el-icon>
        </el-tooltip>
      </div>
      <div
        align="center"
        style="margin-top: 12px"
        v-show="this.$store.state.login_state == '1'"
      >
        <el-radio-group v-model="radio">
          <el-radio-button
            label="所有课程"
            @click="all_courses"
          ></el-radio-button>
          <el-radio-button
            label="我的课程"
            @click="my_courses"
          ></el-radio-button>
        </el-radio-group>
      </div>
      <!-- 章节列表 -->
      <div class="course_outline">
        <el-menu
          class="el-menu-vertical-demo coure_section_in"
          :default-active="defaultActive"
          :default-openeds="defaultOpenedsArray"
          :unique-opened="true"
          v-if="menu_show"
        >
          <el-sub-menu
            v-for="(course, index) in courses"
            :index="course.id"
            :key="index"
          >
            <template #title>
              <el-icon>
                <Reading />
              </el-icon>
              <span style="width: 100%">{{ course.label }}</span>
              <el-tooltip
                class="item"
                effect="dark"
                content="添加章"
                placement="top"
              >
                <el-icon
                  style="font-size: 13px"
                  @click.stop="add_chapter(course.id)"
                  v-show="
                    this.$store.state.login_state == '1' &&
                    edit_tag &&
                    (this.$store.state.username == 'admin' ||
                      this.$store.state.username == 'hongzg')
                  "
                >
                  <Plus />
                </el-icon>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="修改课程"
                placement="right"
              >
                <el-icon
                  style="font-size: 13px"
                  @click.stop="edit_course(course.id)"
                  v-show="
                    this.$store.state.login_state == '1' &&
                    edit_tag &&
                    (this.$store.state.username == 'admin' ||
                      this.$store.state.username == 'hongzg')
                  "
                >
                  <Edit />
                </el-icon>
              </el-tooltip>
            </template>
            <el-sub-menu
              v-for="(chapter, index) in course.children"
              :index="course.id + '-' + chapter.id"
              :key="index"
            >
              <template #title>
                <span style="width: 100%">{{
                  chapter.id + ". " + chapter.label
                }}</span>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="添加节"
                  placement="top"
                >
                  <el-icon
                    style="font-size: 12px"
                    @click.stop="add_section(course.id, chapter.id)"
                    v-show="
                      this.$store.state.login_state == '1' &&
                      edit_tag &&
                      (this.$store.state.username == 'admin' ||
                        this.$store.state.username == 'hongzg')
                    "
                  >
                    <Plus />
                  </el-icon>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="修改章"
                  placement="right"
                >
                  <el-icon
                    style="font-size: 12px"
                    @click.stop="edit_chapter(course.id, chapter.id)"
                    v-show="
                      this.$store.state.login_state == '1' &&
                      edit_tag &&
                      (this.$store.state.username == 'admin' ||
                        this.$store.state.username == 'hongzg')
                    "
                  >
                    <Edit />
                  </el-icon>
                </el-tooltip>
              </template>
              <el-menu-item
                v-for="(section, index) in chapter.children"
                :index="course.id + '-' + chapter.id + '-' + section.id"
                :key="index"
                @click="go_study(course.id, chapter.id, section.id)"
              >
                <template #title>
                  <span style="width: 100%">{{
                    chapter.id + "." + section.id + ". " + section.label
                  }}</span>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="修改节"
                    placement="right"
                  >
                    <el-icon
                      style="font-size: 12px"
                      @click.stop="
                        edit_section(course.id, chapter.id, section.id)
                      "
                      v-show="
                        this.$store.state.login_state == '1' &&
                        edit_tag &&
                        (this.$store.state.username == 'admin' ||
                          this.$store.state.username == 'hongzg')
                      "
                    >
                      <Edit />
                    </el-icon>
                  </el-tooltip>
                </template>
              </el-menu-item>
            </el-sub-menu>
          </el-sub-menu>
        </el-menu>
      </div>
    </el-aside>
    <!-- 新增修改课程 -->
    <el-dialog v-model="add_visible" title="添加课程">
      <el-form>
        <el-form-item label="课程名:" required>
          <el-input v-model="class_name"></el-input>
        </el-form-item>
        <el-form-item class="dialog_button">
          <div>
            <el-button type="primary" @click="onSubmit" :loading="loading"
              >新增</el-button
            >
            <el-button
              @click="
                add_visible = false;
                loading = false;
              "
              >取消</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog v-model="edit_visible" title="修改课程">
      <el-form>
        <el-form-item label="课程名修改为:" required>
          <el-input v-model="edit_class_name"></el-input>
        </el-form-item>
        <el-form-item class="dialog_button">
          <div>
            <el-button type="primary" @click="onEditSubmit" :loading="loading"
              >修改</el-button
            >
            <el-button
              @click="
                edit_visible = false;
                loading = false;
              "
              >取消</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 新增修改章 -->
    <el-dialog v-model="add_chapter_visible" title="添加章">
      <el-form label-width="85px" :label-position="left">
        <el-form-item label="章名:" required>
          <el-input v-model="chapter_name"></el-input>
        </el-form-item>
        <el-form-item label="第一节:" required>
          <el-input v-model="first_section"></el-input>
        </el-form-item>
        <el-form-item class="dialog_button">
          <div>
            <el-button
              type="primary"
              @click="onSubmitChapter"
              :loading="loading"
              >添加</el-button
            >
            <el-button
              @click="
                add_chapter_visible = false;
                loading = false;
              "
              >取消</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog v-model="edit_chapter_visible" title="修改章">
      <el-form>
        <el-form-item label="章名修改为:" required>
          <el-input v-model="edit_chapter_name"></el-input>
        </el-form-item>
        <el-form-item class="dialog_button">
          <div>
            <el-button
              type="primary"
              @click="onEditSubmitChapter"
              :loading="loading"
              >修改</el-button
            >
            <el-button
              @click="
                edit_chapter_visible = false;
                loading = false;
              "
              >取消</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 新增修改节 -->
    <el-dialog v-model="add_section_visible" title="添加小节">
      <el-form>
        <el-form-item label="小节名:" required>
          <el-input v-model="section_name"></el-input>
        </el-form-item>
        <el-form-item class="dialog_button">
          <div>
            <el-button
              type="primary"
              @click="onSubmitSection"
              :loading="loading"
              >添加</el-button
            >
            <el-button
              @click="
                add_section_visible = false;
                loading = false;
              "
              >取消</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog v-model="edit_section_visible" title="修改小节">
      <el-form>
        <el-form-item label="小节名修改为:" required>
          <el-input v-model="edit_section_name"></el-input>
        </el-form-item>
        <el-form-item class="dialog_button">
          <div>
            <el-button
              type="primary"
              @click="onEditSubmitSection"
              :loading="loading"
              >修改</el-button
            >
            <el-button
              @click="
                edit_section_visible = false;
                loading = false;
              "
              >取消</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 内容栏 -->
    <el-main>
      <courseContentVue
        v-bind:course_id="current_course_id"
        v-bind:chapter_id="current_chapter_id"
        v-bind:section_id="current_section_id"
        v-bind:course="course_content"
        v-bind:edit_tag="edit_tag"
        v-on:paragraph="get_paragraph"
        v-if="course_paragraph"
      ></courseContentVue>
      <sectionContentVue
        v-bind:paragraph="paragraph_content"
        v-on:change_paragraph="go_section"
        v-else
      ></sectionContentVue>
    </el-main>
  </el-container>
</template>

<script>
import { defineComponent } from "vue";
import { Reading, Plus, Edit } from "@element-plus/icons";
import courseContentVue from "../components/course_content.vue";
import sectionContentVue from "../components/section_content.vue";
export default defineComponent({
  components: {
    Reading,
    Plus,
    courseContentVue,
    Edit,
    sectionContentVue,
  },
  data() {
    return {
      left: "left",
      loading: false,
      radio: "所有课程",
      edit_tag: false,
      defaultActive: "",
      defaultOpenedsArray: [],
      menu_show: true,

      add_visible: false,
      add_chapter_visible: false,
      add_section_visible: false,

      class_name: "",
      chapter_name: "",
      first_section: "",
      section_name: "",

      current_course: "",
      current_chapter: "",
      current_section: "",

      current_course_id: "",
      current_chapter_id: "",
      current_section_id: "",

      edit_visible: false,
      edit_class_name: "",
      edit_chapter_visible: false,
      old_chapter_name: "",
      edit_chapter_name: "",
      edit_section_visible: false,
      edit_section_name: "",

      courses: [],
      course_content: [],

      paragraph_content: {},
      course_paragraph: true,

      path: "https://api.class.yingshinet.com/",
    };
  },
  methods: {
    all_courses() {
      this.edit_tag = false;
      this.get_course();
    },
    my_courses() {
      // this.menu_show = !this.menu_show;
      this.defaultActive = null;
      this.current_course_id = "";
      this.current_chapter_id = "";
      this.current_section_id = "";
      this.course_content = [];
      this.edit_tag = true;
      var my_course = [];
      var arr = this.$store.state.relate_course.split(",");
      for (let i = 0; i < this.courses.length; i++) {
        if (arr.indexOf(this.courses[i].id) != -1) {
          my_course.push(this.courses[i]);
        }
      }
      this.courses = my_course;
    },
    get_paragraph(data) {
      this.paragraph_content = data;
      this.course_paragraph = false;
    },
    go_section(data) {
      console.log(data);
      this.course_paragraph = true;
    },
    add_course() {
      this.add_visible = true;
    },
    edit_course(course_id) {
      this.current_course = course_id;
      this.edit_class_name = this.courses.filter((item) => {
        return item.id == course_id;
      })[0].label;
      this.edit_visible = true;
    },
    add_chapter(course_id) {
      this.current_course = course_id;
      this.add_chapter_visible = true;
    },
    edit_chapter(course_id, chapter_id) {
      this.current_course = course_id;
      this.current_chapter = chapter_id;
      this.old_chapter_name = this.courses.filter((item) => {
        return item.id == course_id;
      })[0].children[chapter_id - 1].label;
      this.edit_chapter_name = this.old_chapter_name;
      this.edit_chapter_visible = true;
    },
    add_section(course_id, chapter_id) {
      this.current_course = course_id;
      this.current_chapter = chapter_id;
      this.add_section_visible = true;
    },
    edit_section(course_id, chapter_id, section_id) {
      this.current_course = course_id;
      this.current_chapter = chapter_id;
      this.current_section = section_id;
      this.edit_section_name = this.courses.filter((item) => {
        return item.id == course_id;
      })[0].children[chapter_id - 1].children[section_id - 1].label;
      this.edit_section_visible = true;
    },
    go_study(course_id, chapter_id, section_id) {
      this.current_course_id = course_id;
      this.current_chapter_id = chapter_id;
      this.current_section_id = section_id;
      this.course_paragraph = true;
      this.axios
        .post(this.path + "outline/select", {
          class_id: course_id,
          chapter_id: chapter_id,
          section_id: section_id,
        })
        .then((response) => {
          if (response.data.msg == "success") {
            this.course_content = response.data.data;
            console.log(this.course_content);
          }
        });
    },
    onSubmit() {
      if (this.class_name != "") {
        this.loading = true;
        this.axios
          .post(this.path + "class/insert", { class_name: this.class_name })
          .then((response) => {
            if (response.data.msg == "Success") {
              this.$notify({
                title: "提示",
                message: "课程添加成功！",
                type: "success",
              });
              this.get_course();
            } else {
              this.$notify({
                title: "错误",
                message: "课程添加失败！",
              });
            }
          });
        this.add_visible = false;
        this.loading = false;
      } else {
        this.$message({
          message: "输入课程名！Please",
          type: "warning",
        });
      }
    },
    onEditSubmit() {
      if (this.edit_class_name != "") {
        this.loading = true;
        this.axios
          .post(this.path + "class/update/" + this.current_course, {
            class_name: this.edit_class_name,
          })
          .then((response) => {
            if (response.data.msg == "Success") {
              this.$notify({
                title: "提示",
                message: "课程修改成功！",
                type: "success",
              });
              // this.edit_tag = false
              // this.radio = '所有课程'
              this.get_own_course();
            } else {
              this.$notify({
                title: "错误",
                message: "课程修改失败！",
              });
            }
          });
        this.edit_visible = false;
        this.loading = false;
      } else {
        this.$message({
          message: "输入课程名！Please",
          type: "error",
        });
      }
    },
    onSubmitChapter() {
      if (this.chapter_name != "" && this.first_section != "") {
        this.loading = true;
        this.axios
          .post(this.path + "chapters/insert", {
            class_id: this.current_course,
            chapter_id:
              this.courses.filter((item) => {
                return item.id == this.current_course;
              })[0].children.length + 1,
            chapter_name: this.chapter_name,
            section_id: 1,
            section_name: this.first_section,
          })
          .then((response) => {
            if (response.data.msg == "Success") {
              this.$notify({
                title: "提示",
                message: "章节添加成功！",
                type: "success",
              });
              this.get_own_course();
            } else {
              this.$notify({
                title: "错误",
                message: "章节添加失败！",
              });
            }
          });
        this.add_chapter_visible = false;
        this.loading = false;
      } else {
        this.$message({
          message: "章与节缺一不可！Please",
          type: "warning",
        });
      }
    },
    onEditSubmitChapter() {
      if (this.edit_chapter_name != null) {
        this.loading = true;
        this.axios
          .post(this.path + "chapters/updatechapter", {
            old_name: this.old_chapter_name,
            new_name: this.edit_chapter_name,
          })
          .then((response) => {
            if (response.data.msg == "Success") {
              this.$notify({
                title: "提示",
                message: "章名修改成功！",
                type: "success",
              });
              this.get_own_course();
            } else {
              this.$notify({
                title: "错误",
                message: "章名修改失败！",
              });
            }
          });
        this.edit_chapter_visible = false;
        this.loading = false;
      } else {
        this.$message({
          message: "输入章名！Please",
          type: "warning",
        });
      }
    },
    onSubmitSection() {
      if (this.section_name != null) {
        this.axios
          .post(this.path + "chapters/insert", {
            class_id: this.current_course,
            chapter_id: this.current_chapter,
            chapter_name: this.courses.filter((item) => {
              return item.id == this.current_course;
            })[0].children[this.current_chapter - 1].label,
            section_id:
              this.courses.filter((item) => {
                return item.id == this.current_course;
              })[0].children[this.current_chapter - 1].children.length + 1,
            section_name: this.section_name,
          })
          .then((response) => {
            if (response.data.msg == "Success") {
              this.$notify({
                title: "提示",
                message: "小节添加成功！",
                type: "success",
              });
              this.get_own_course();
            } else {
              this.$notify({
                title: "错误",
                message: "小节添加失败！",
              });
            }
          });
        this.add_section_visible = false;
        this.loading = false;
      } else {
        this.$message({
          message: "小节名输入！Please",
          type: "warning",
        });
      }
    },
    onEditSubmitSection() {
      if (this.edit_section_name != null) {
        this.axios
          .post(this.path + "chapters/update", {
            class_id: this.current_course,
            chapter_id: this.current_chapter,
            section_id: this.current_section,
            section_name: this.edit_section_name,
          })
          .then((response) => {
            if (response.data.msg == "Success") {
              this.$notify({
                title: "提示",
                message: "小节修改成功！",
                type: "success",
              });
              this.get_own_course();
            } else {
              this.$notify({
                title: "错误",
                message: "小节修改失败！",
              });
            }
          });
        this.edit_section_visible = false;
        this.loading = false;
      } else {
        this.$message({
          message: "请输入小节名！Please",
          type: "warning",
        });
      }
    },
    get_course() {
      var all_course = [];
      this.axios.get(this.path + "chapters").then((response) => {
        all_course = response.data.data;
        var arr = [];
        for (let i = 0; i < response.data.count; i++) {
          arr.push(response.data.data[i].id);
        }
        this.axios.get(this.path + "classes").then((res) => {
          for (let i = 0; i < res.data.count; i++) {
            if (arr.indexOf(res.data.data[i].class_id) == "-1") {
              all_course.push({
                children: [],
                id: res.data.data[i].class_id,
                label: res.data.data[i].class_name,
              });
            }
          }
          all_course.sort(function (a, b) {
            var x = parseInt(a["id"]);
            var y = parseInt(b["id"]);
            return x < y ? -1 : x < y ? 1 : 0;
          });
          this.courses = all_course;
          if (!(this.$store.state.username == 'admin')) {
            this.courses = this.courses.filter((item) => {
              return item.id === '17'
            })
          }
        });
      });
    },
    get_own_course() {
      var all_course = [];
      this.axios.get(this.path + "chapters").then((response) => {
        all_course = response.data.data;
        var arr = [];
        for (let i = 0; i < response.data.count; i++) {
          arr.push(response.data.data[i].id);
        }
        this.axios.get(this.path + "classes").then((res) => {
          for (let i = 0; i < res.data.count; i++) {
            if (arr.indexOf(res.data.data[i].class_id) == "-1") {
              all_course.push({
                children: [],
                id: res.data.data[i].class_id,
                label: res.data.data[i].class_name,
              });
            }
          }
          all_course.sort(function (a, b) {
            var x = parseInt(a["id"]);
            var y = parseInt(b["id"]);
            return x < y ? -1 : x < y ? 1 : 0;
          });
          this.courses = all_course;
          if (!(this.$store.state.username == 'admin')) {
            this.courses = this.courses.filter((item) => {
              return item.id === '17'
            })
          }
          this.my_courses()
        });
      });
    },
  },
  created() {
    this.course = [];
    this.get_course();
    if (this.$route.query.section_id != undefined) {
      this.go_study(
        this.$route.query.class_id,
        this.$route.query.chapter_id,
        this.$route.query.section_id
      );
      this.defaultActive =
        this.$route.query.class_id +
        "-" +
        this.$route.query.chapter_id +
        "-" +
        this.$route.query.section_id;
      this.radio = "我的课程";
      this.edit_tag = true;
    }
  },
});
</script>
<style scoped>
.aside {
  width: 21vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.05);
  min-width: fit-content;
}
.add_style {
  font-size: 18px;
  float: right;
  margin-top: 4px;
  margin-left: 6px;
}
.add_style:hover {
  cursor: pointer;
  transform: scale(1.4, 1.4);
  transition: 0.5s transform;
}
.outline_title {
  margin: 20px 20px 0 20px;
  color: rgb(46, 89, 167);
  font-size: 20px;
}
.course_outline {
  margin-top: 15px;
}
.dialog_button {
  margin-top: 30px;
  text-align: right;
}
:deep(.el-sub-menu .el-sub-menu__icon-arrow) {
  display: none;
}
:deep(.el-icon:hover) {
  transform: scale(1.4, 1.4);
  transition: 0.5s transform;
}
:deep(.el-menu-item.is-active) {
  color: rgb(46, 89, 167);
  font-weight: bold;
}
:deep(.el-dialog__body) {
  padding: 30px 20px 10px 20px;
}
:deep(.el-button--primary) {
  --el-button-background-color: rgb(46, 89, 167);
  --el-button-border-color: rgb(46, 89, 167);
  --el-button-hover-color: rgb(46, 89, 167, 0.8);
  --el-button-active-background-color: rgb(46, 89, 167);
  --el-button-active-border-color: rgb(46, 89, 167);
}
:deep(.el-dialog__title) {
  line-height: var(--el-dialog-font-line-height);
  font-size: 20px;
  color: rgb(46, 89, 167);
}
:deep(.el-sub-menu__title) {
  padding: 0 10px;
}
:deep(.el-sub-menu .el-menu-item) {
  padding: 0 10px;
}
:deep(.el-radio-button) {
  --el-radio-button-checked-background-color: #2e59a7;
  --el-radio-button-checked-font-color: var(--el-color-white);
  --el-radio-button-checked-border-color: rgba(255, 255, 255, 0);
  --el-radio-button-disabled-checked-fill: var(--el-border-color-extra-light);
}
:deep(.el-radio-button__inner :hover) {
  color: #2e59a7;
}
@keyframes comin {
  0% {
    transform: translateX(-100px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes comout {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(100px);
  }
}
:deep(.v-enter-active) {
  animation: comin 0.6s;
}
:deep(.v-leave-active) {
  animation: comout 0.6s;
}
</style>