<template>
    <div class="content_style">
        <div v-if="course.length != 0 || courses_content.length != 0">
            <el-card
                class="content_card"
                v-for="(course_content, index) in (course.length == 0) ? courses_content : course"
                :key="index"
            >
                <div
                    class="content_text"
                    :style="{
                        width: course_content.pic != null && course_content.pic != '' ? '72%' : '97%'
                    }"
                >
                    <div class="max_line">{{ course_content.text }}</div>
                </div>
                <div
                    class="content_image"
                    :style="{
                        width: course_content.pic != null && course_content.pic != '' ? '22%' : '0%'
                    }"
                >
                    <el-image
                        v-show="course_content.pic != null && course_content.pic != ''"
                        class="content_image_style"
                        :src="course_content.pic"
                        :preview-src-list="new Array(course_content.pic)"
                    ></el-image>
                </div>
                <div
                    :style="{
                        width: course_content.pic != null && course_content.pic != '' ? '72%' : '97%'
                    }"
                >
                    <div
                        class="more"
                        @click="more(course_id, chapter_id, section_id, course_content.paragraph_id)"
                    >更多详情...</div>
                    <div
                        class="more"
                        @click="fix_paragraph(course_id, chapter_id, section_id, course_content.paragraph_id)"
                        v-show="this.$store.state.login_state == '1' && edit_tag"
                    >修改本段</div>
                    <span class="creator">创建人：{{ course_content.long_video }}</span>
                </div>
            </el-card>
            <div style="height: 12vh"></div>
        </div>
        <div v-else class="no_content" align="center">暂无数据</div>
        <el-tooltip class="item" effect="dark" content="添加段落" placement="left">
            <el-button
                v-show="this.section_id != '' && this.$store.state.login_state == '1' && edit_tag"
                class="new_paragraph"
                :icon="Plus"
                circle
                @click="new_paragraph"
            ></el-button>
        </el-tooltip>
    </div>
</template>

<script>
import { Plus } from '@element-plus/icons'
export default {
    props: {
        course_id: String,
        chapter_id: String,
        section_id: String,
        course: Array,
        edit_tag: Boolean,
    },
    data() {
        return {
            Plus,
            paragraph_content: {},
            courses_content: [],
            self_course: [],
        };
    },
    methods: {
        new_paragraph() {
            console.log(this.course_id, this.chapter_id, this.section_id)
            this.$router.push({
                path: "/modify",
                query: {
                    class_id: this.course_id,
                    chapter_id: this.chapter_id,
                    section_id: this.section_id,
                }
            });
        },
        more(course_id, chapter_id, section_id, paragraph_id) {
            this.axios.post("https://api.class.yingshinet.com/outline/select",
                {
                    class_id: course_id,
                    chapter_id: chapter_id,
                    section_id: section_id,
                    paragraph_id: paragraph_id,
                })
                .then(response => {
                    if (response.data.msg == "success") {
                        this.paragraph_content = response.data.data[0]
                        this.$emit('paragraph', this.paragraph_content)
                    }
                })
        },
        fix_paragraph(course_id, chapter_id, section_id, paragraph_id) {
            this.$router.push({
                path: "/modify",
                query: {
                    class_id: course_id,
                    chapter_id: chapter_id,
                    section_id: section_id,
                    paragraph_id: paragraph_id,
                }
            });
        }
    },
};
</script>

<style>
.el-main {
    --el-main-padding: 1.5vh 15px;
}
.content_style {
    height: 97vh;
}
.content_card {
    height: auto;
    width: 100%;
}
.el-card {
    border-radius: 10px;
    margin-bottom: 1.5vh;
}
.el-card__body {
    padding: 20px 0 20px 20px;
}
.content_text {
    display: inline-block;
    padding: 10px 2vw 10px 0;
    font-size: 2vh;
    vertical-align: middle;
    text-indent: 2em;
}
.max_line {
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.content_image {
    display: inline-block !important;

    vertical-align: middle;
}
.content_image_style {
    border-radius: 5px;
    position: relative;
    bottom: -10px;
}
.content_video {
    width: 22%;
    display: inline-block !important;
    vertical-align: middle;
}
.short_video {
    width: 100%;
    border-radius: 0.6vw;
}
.no_content {
    padding-top: 38vh;
    font-size: 5vh;
    font-family: Microsoft JhengHei;
    color: rgba(0, 0, 0, 0.3);
}
</style>
<style scoped>
.more {
    font-size: 14px !important;
    color: rgb(15, 100, 150);
    display: inline-block;
    padding-right: 15px;
}
.creator {
    font-weight: 600;
    font-size: 14px !important;
    float: right;
    padding-right: 6px;
    color: rgb(25, 148, 0);
}
@keyframes more_scale {
    0% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(1.4, 1.4);
    }
}
.more:hover {
    cursor: pointer;
    color: rgb(50, 150, 220);
}
.new_paragraph {
    position: fixed;
    bottom: 70px;
    right: 50px;
    height: 55px;
    width: 55px;
    background-color: #2e59a7;
}
.new_paragraph:hover {
    background-color: #5377b9;
}
:deep(.el-icon svg) {
    color: #fff;
}
:deep(.el-icon) {
    font-size: 22px;
}
</style>